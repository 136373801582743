import {
  get, excel
} from '../utils/http';

// 获取列表
export function getLXPageList(data) {
  return get('bbgl/SY_BBGL_LXBB/GetPageList/', data);
}

// 导出离校信息
export function exportLXExcel(data) {
  return excel('bbgl/SY_BBGL_LXBB/ExportListExcel/', data);
}

// 获取列表
export function getFXPageList(data) {
  return get('bbgl/SY_BBGL_FXBB/GetPageList/', data);
}

// 导出返校信息
export function exportFXExcel(data) {
  return excel('bbgl/SY_BBGL_FXBB/ExportListExcel/', data);
}
