<!--
* 创建人：罗兴
* 日 期：
* 描 述：离校报备管理
-->
<!--view 模板-->
<template>
  <div class="container">
    <!--=====================================顶部标题======================================-->
    <div class="page-title">离校报备管理</div>
    <!--==============================按钮===================================================-->
    <div class="btngroup">
      <el-button
        type="primary"
        size="mini"
        @click="exportToExcel"
        v-if="hasPerms('sy_export')"
        >导出Excel</el-button
      >
      <el-button type="primary" size="mini" @click="search">查询</el-button>
    </div>
    <div class="sm_search">
      <div class="sm_search_group">
        <div class="sm_search_item">
          <div
            v-html="'学&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;号'"
            class="sm_search_title"
          ></div>
          <el-input size="mini" v-model="xh"></el-input>
        </div>
        <div class="sm_search_item">
          <div
            v-html="'姓&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;名'"
            class="sm_search_title"
          ></div>
          <el-input size="mini" v-model="xm"></el-input>
        </div>
        <div class="sm_search_item">
          <div
            v-html="'年&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;级'"
            class="sm_search_title"
          ></div>
          <el-select
            v-model="nj"
            style="display: block; width: 178px"
            size="mini"
            clearable
          >
            <el-option
              v-for="(item, index) in njlist"
              :key="index"
              :label="item.nj"
              :value="item.nj"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="sm_search_group">
        <div class="sm_search_item">
          <div class="sm_search_title">二级学院</div>
          <el-select
            v-model="bm"
            style="display: block; width: 178px"
            size="mini"
            @change="bmchange"
            clearable
          >
            <el-option
              v-for="(item, index) in bmlist"
              :key="index"
              :label="item.bmmc"
              :value="item.bmbh"
            ></el-option>
          </el-select>
        </div>
        <div class="sm_search_item">
          <div class="sm_search_title">专业名称</div>
          <el-select
            v-model="zy"
            style="display: block; width: 178px"
            size="mini"
            @change="zychange"
            clearable
          >
            <el-option
              v-for="(item, index) in zylist"
              :key="index"
              :label="item.zymc"
              :value="item.zybh"
            ></el-option>
          </el-select>
        </div>
        <div class="sm_search_item">
          <div class="sm_search_title">班级名称</div>
          <el-select
            v-model="bj"
            style="display: block; width: 178px"
            size="mini"
            clearable
          >
            <el-option
              v-for="(item, index) in bjlist"
              :key="index"
              :label="item.bjmc"
              :value="item.bjbh"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="sm_search_group">
        <div class="sm_search_item">
          <div class="sm_search_title">省</div>
          <el-select
            v-model="province"
            clearable
            @change="provincechange"
            style="display: block; width: 178px"
            size="mini"
          >
            <el-option
              v-for="(item, index) in provincelist"
              :key="index"
              :label="item.province"
              :value="item.province"
            ></el-option>
          </el-select>
        </div>
        <div class="sm_search_item">
          <div class="sm_search_title">市</div>
          <el-select
            v-model="city"
            clearable
            @change="citychange"
            style="display: block; width: 178px"
            size="mini"
          >
            <el-option
              v-for="(item, index) in citylist"
              :key="index"
              :label="item.city"
              :value="item.city"
            ></el-option>
          </el-select>
        </div>
        <div class="sm_search_item">
          <div class="sm_search_title">区/县</div>
          <el-select
            v-model="county"
            clearable
            style="display: block; width: 178px"
            size="mini"
          >
            <el-option
              v-for="(item, index) in countylist"
              :key="index"
              :label="item.area"
              :value="item.area"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="sm_search_group">
        <div class="sm_search_item">
          <div class="sm_search_title">外出时间</div>
          <el-date-picker
            v-model="qjrq"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="mini"
            style="width: 430px"
          >
          </el-date-picker>
        </div>
        <div class="sm_search_item">
          <div class="sm_search_title">外出方式</div>
          <el-select
            v-model="jtgj"
            clearable
            style="display: block; width: 178px"
            size="mini"
          >
            <el-option
              v-for="(item, index) in jtgjlist"
              :key="index"
              :label="item.zdz"
              :value="item.zdz"
            ></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <!---->
    <!--=============================表格========================================-->
    <div class="c_table">
      <el-table
        stripe
        v-loading="tableloading"
        :data="dataPageList"
        :height="windowHeight - 270"
        border
        style="width: 100%"
        header-cell-class-name="tableStyle"
        highlight-current-row
        @current-change="currentRowChange"
        :row-style="{ height: '20px' }"
        :cell-style="{ padding: '5px' }"
      >
        <el-table-column prop="xh" label="学号" width="150"> </el-table-column>
        <el-table-column prop="xm" label="姓名" width="100"> </el-table-column>
        <el-table-column prop="nj" label="年级" width="100"> </el-table-column>
        <el-table-column prop="bmmc" label="二级学院" width="300">
        </el-table-column>
        <el-table-column prop="zymc" label="专业名称" width="350">
        </el-table-column>
        <el-table-column prop="bjmc" label="班级名称" width="300">
        </el-table-column>
        <el-table-column prop="kssj" label="外出时间" width="500">
          <template slot-scope="scop">
            {{
              dateFormat2(scop.row.kssj) + ' 至 ' + dateFormat2(scop.row.jssj)
            }}
          </template>
        </el-table-column>
        <el-table-column prop="province" label="外出地点" width="500">
          <template slot-scope="scop">
            {{
              scop.row.province +
              scop.row.city +
              scop.row.county +
              scop.row.xzjd
            }}
          </template>
        </el-table-column>
        <el-table-column prop="jtgj" label="出行方式" width="200">
        </el-table-column>
      </el-table>
      <!--=======================================分页================================================-->
      <div class="pagebox">
        <el-pagination
          @current-change="handleCurrentChange"
          :current-page="pagination.page"
          :page-size="pagination.rows"
          layout="total, prev, pager, next, jumper"
          :total="pagination.records"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<!-- javascript脚本-->
<script>
import moment from 'moment'
import {
  getXSIndexData,
  GetZYListByQX,
  GetBJListByQX,
  GetSF,
  GetSZS,
  GetSZX,
  getCode,
} from '../../api/jcxx'
import { getLXPageList, exportLXExcel } from '../../api/bbgl'
import { getAuthorizeButtonColumnList } from '../../api/system'
export default {
  data() {
    return {
      dialogFormVisible: false,
      keyValue: '',
      xh: '',
      xm: '',
      nj: '',
      njlist: [],
      bm: '',
      bmlist: [],
      zy: '',
      zylist: [],
      bj: '',
      bjlist: [],
      province: '',
      provincelist: [],
      city: '',
      citylist: [],
      county: '',
      countylist: [],
      jtgj: '',
      jtgjlist: [],
      qjrq: '',
      pagination: {
        rows: 15,
        page: 1,
        sord: 'DESC',
        records: 0,
        total: 0,
      },
      windowHeight: document.documentElement.clientHeight - 150, // 实时屏幕高度
      dataPageList: [],
      tableloading: true,
      buttonauth: {},
    }
  },
  created() {
    getAuthorizeButtonColumnList({ url: this.$route.path }).then((res) => {
      if (res.code === 200) {
        this.buttonauth = res.data.btns
      } else {
        this.buttonauth = {}
      }
    })
    getXSIndexData().then((res) => {
      if (res.code === 200) {
        this.njlist = res.data.njlist
        this.bmlist = res.data.bmlist
      }
    })
    GetSF().then((res) => {
      if (res.code === 200) {
        this.provincelist = res.data
      }
    })
    getCode({ queryJson: JSON.stringify({ LXDM: 'JTGJLX' }) })
      .then((res) => {
        if (res.code === 200) {
          this.jtgjlist = res.data
        } else {
          this.jtgjlist = []
        }
      })
      .catch((err) => {
        this.$message.error(err)
        this.zzmm = []
      })
    this.getDataPageList()
  },
  methods: {
    dateFormat2(value) {
      if (!value) {
        return ''
      }
      return moment(value).format('YYYY年MM月DD日 HH:mm')
    },
    // 判断是否有该按钮的权限
    hasPerms(code) {
      if (Object.prototype.hasOwnProperty.call(this.buttonauth, code)) {
        return true
      } else {
        return false
      }
    },
    // 获取专业列表
    getZY() {
      GetZYListByQX({ BMBH: this.bm }).then((res) => {
        if (res.code === 200) {
          this.zylist = res.data
        }
      })
    },
    // 获取班级列表
    getBJ() {
      GetBJListByQX({ ZYBH: this.zy }).then((res) => {
        if (res.code === 200) {
          this.bjlist = res.data
        }
      })
    },
    bmchange() {
      this.zy = ''
      this.bj = ''
      this.getZY()
    },
    zychange() {
      this.bj = ''
      this.getBJ()
    },
    provincechange() {
      this.city = ''
      this.county = ''
      GetSZS({ sfmc: this.province }).then((res) => {
        if (res.code === 200) {
          this.citylist = res.data
        }
      })
    },
    citychange() {
      this.county = ''
      GetSZX({ smc: this.city }).then((res) => {
        if (res.code === 200) {
          this.countylist = res.data
        }
      })
    },
    search() {
      this.pagination.page = 1
      this.getDataPageList()
    },
    // 获取分页信息
    getDataPageList() {
      this.tableloading = true
      getLXPageList({
        queryJson: JSON.stringify({
          xh: this.xh,
          xm: this.xm,
          nj: this.nj,
          bm: this.bm,
          zy: this.zy,
          bj: this.bj,
          province: this.province,
          city: this.city,
          county: this.county,
          kssj: this.qjrq ? this.dateFormat3(this.qjrq[0]) : '',
          jssj: this.qjrq ? this.dateFormat3(this.qjrq[1]) : '',
          jtgj: this.jtgj,
        }),
        pagination: JSON.stringify(this.pagination),
      })
        .then((res) => {
          if (res.code === 200) {
            this.tableloading = false
            this.pagination.page = res.data.page
            this.pagination.total = res.data.total
            this.pagination.records = res.data.records
            this.dataPageList = res.data.rows
          } else {
            this.tableloading = false
          }
        })
        .catch((err) => {
          console.log(err)
        })
    },
    // 当前行发生变化
    currentRowChange(currentRow) {
      if (!currentRow) {
        this.keyValue = ''
      } else {
        this.keyValue = currentRow.id
      }
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`)
      this.pagination.page = val
      this.getDataPageList()
    },
    exportToExcel() {
      this.$message.info('数据导出中，请耐心等待！')
      exportLXExcel({
        queryJson: JSON.stringify({
          xh: this.xh,
          xm: this.xm,
          nj: this.nj,
          bm: this.bm,
          zy: this.zy,
          bj: this.bj,
          province: this.province,
          city: this.city,
          county: this.county,
          kssj: this.qjrq ? this.dateFormat3(this.qjrq[0]) : '',
          jssj: this.qjrq ? this.dateFormat3(this.qjrq[1]) : '',
          jtgj: this.jtgj,
        }),
        fileName: '离校信息',
      }).then((res) => {
        console.log(res)
        var blob = new Blob([res.data], {
          type: 'application/vnd.ms-excel;charset=utf-8',
        })
        // 针对于IE浏览器的处理, 因部分IE浏览器不支持createObjectURL
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, res.fileName)
        } else {
          var downloadElement = document.createElement('a')
          var href = window.URL.createObjectURL(blob) // 创建下载的链接
          downloadElement.href = href
          downloadElement.download = res.fileName // 下载后文件名
          document.body.appendChild(downloadElement)
          downloadElement.click() // 点击下载
          document.body.removeChild(downloadElement) // 下载完成移除元素
          window.URL.revokeObjectURL(href) // 释放掉blob对象
        }
      })
    },
    dateFormat3: function (value) {
      if (!value) {
        return ''
      }
      return moment(value).format('YYYY-MM-DD HH:mm:ss')
    },
  },
  mounted() {},
}
</script>
<!-- CSS样式 -->
<style  scoped>
.container {
  width: 100%;
  height: 100%;
  max-height: 100%;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #ccc;
  position: relative;
  overflow: hidden;
}
.page-title {
  height: 32px;
  line-height: 32px;
  border-bottom: 1px solid #ccc;
  color: #666;
  font-weight: bold;
  padding-left: 9px;
  font-size: 12px;
  width: 100%;
  box-sizing: border-box;
}
.btngroup {
  height: 28px;
  padding: 3px !important;
  width: 98%;
  margin: 2px auto;
  overflow: hidden;
  min-width: 600px;
  text-align: left;
}
.sm_search {
  padding: initial;
  border-top: 1px solid #ddd !important;
  background-color: #f3f3f3;
  width: 100%;
}
.sm_search_group {
  display: flex;
  padding: 5px 0 5px 0;
}
.sm_search_item {
  display: flex;
  align-items: center;
  margin-left: 10px;
  font-size: 14px;
}
.sm_search_title {
  min-width: 65px;
}
.el-table >>> .tableStyle {
  background-color: #f8f8f8 !important;
  color: #333;
  font-weight: 400;
  padding: 5px 0;
  text-align: center;
  font-weight: 700;
}
>>> .el-table .el-table__body tr.el-table__row.current-row td,
.el-table__body tr.current-row > td,
.el-table__body tr.hover-row.current-row > td,
.el-table__body tr.hover-row.el-table__row.current-row > td,
.el-table__body tr.hover-row.el-table__row > td,
.el-table__body tr.hover-row > td {
  background-color: #409eff !important;
  color: white;
}
</style>
